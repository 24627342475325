* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.6;
}

.todo {
  padding: 5px 15px;
  margin-bottom: 5px;
  background: #f4f4f4;
  display: flex;
  justify-content: space-between;
}

.todo button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

input[type="text"] {
  margin: 5px 0;
  padding: 5px;
  width: 100%;
  font-size: 1.1rem;
}

input[type="submit"] {
  width: 100%;
  background: #333;
  color: #fff;
  padding: 5px;
  font-size: 1.1rem;
  cursor: pointer;
}

.custom-container {
  padding: 1 rem;
}
